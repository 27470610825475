import {HeroPreviewType} from 'blocks/hero/hero_preview'
import {OverviewType} from 'blocks/overview/overview'
import {PreviewType} from 'layout/preview/previewtype'
import {LinkType, PreviewImageType, WysiwygType} from 'types/basictypes'
import {getImageFromField} from 'util/drupal'

export type OverviewPreviewType = {
	type: 'overview'
	title: string
	items: Array<
		| {
				type: 'overview_link'
				link: {
					title: string
					url: string
					preview: PreviewType
				}
		  }
		| {
				type: 'overview_item'
				link: LinkType
				text: WysiwygType
				image: PreviewImageType
		  }
	>
}

export function previewProcessOverview(
	block: OverviewPreviewType
): OverviewType {
	return {
		...block,
		items: block.items.map((item) => {
			switch (item.type) {
				case 'overview_link':
					const preview = item.link?.preview
					return {
						link: {url: preview?.url},
						image: preview?.image,
						text: `<h5>${preview?.title}</h5>${preview?.description}`
					}

				case 'overview_item':
					return {
						link: item.link,
						image: item.image,
						text: item.text
					}
			}
		})
	}
}
