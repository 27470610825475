import { ImageType, LinkType, WysiwygType } from 'types/basictypes'
import { ImageblockType } from 'blocks/imageblock/imageblock'

export type ImageblockPreviewType = {
  type: 'imageblock',
  title: string
  image: ImageType
  caption: WysiwygType
  link: LinkType
  size: { name: 'small' | 'medium' | 'large' | 'fullwidth' | 'leaflet' }
}

export function previewProcessImageblock(block: ImageblockPreviewType): ImageblockType {
  return {
    ...block,
    size: block.size ? block.size.name : null
  }
}
