import './contentsidebar.scss'

import {Theme} from 'layout/theme'
import React, {HTMLProps} from 'react'
import {styler} from 'util/style'

const styles = styler('contentsidebar', ['content', 'sidebar'])

export const Contentsidebar: React.FC<HTMLProps<HTMLDivElement>> = ({
	children,
	...props
}) => (
	<Theme.OuterContainer>
		<div className={styles().mergeProps(props)}>
			<Theme.Container>{children}</Theme.Container>
		</div>
	</Theme.OuterContainer>
)

export const ContentsidebarContent: React.FC<HTMLProps<HTMLDivElement>> = ({
	children,
	...props
}) => <div className={styles.content().mergeProps(props)}>{children}</div>

export const ContentsidebarSidebar: React.FC = ({children}) => (
	<div className={styles.sidebar()}>{children}</div>
)
