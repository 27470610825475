import './errors.scss'

import React, {useState} from 'react'
import {useFetcher} from 'util/fetcher'
import {styler} from 'util/style'
import {Link} from 'gatsby'

const style = styler('errors', ['link'])

export const Errors: React.FC = () => {
  const [open, setOpen] = useState(false)
  const {data} = useFetcher(`${process.env.GATSBY_DRUPAL_URL}/api/errors`)

  const sortedData = (data || []).sort((a,b) => {
    if(b.type === a.type) return b.errors - a.errors
    return b.type - a.type
  })

  return <div className={style().is({open})}>
    <div className={style('toggler')} onClick={() => setOpen(!open)}>
      {open ? '<' : 'Errors >'}
    </div>
    <div className={style('links')}>
      {(data || []).map(record => <ErrorsLink {...record} key={record.node_id} />)}
    </div>
  </div>
}

const ErrorsLink: React.FC<{
  node_id: number
  errors: number
  title: string
  type: string
  url: string
  preview_url: string
}> = ({type, errors, title, preview_url, url}) => {

  return <div className={style.link()}>
    <Link to={preview_url} >
      {type} | {title}
      <div className={style.link('errors')}>
        {errors}
      </div>
    </Link>
  </div>
}
