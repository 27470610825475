import {ServicesType} from 'blocks/services/services'
import {ImageType, LinkType, PreviewImageType, WysiwygType} from 'types/basictypes'
import {PreviewType} from 'layout/preview/previewtype'

export type ServicesPreviewType = {
  type: 'services'
  background_image: PreviewImageType
  display_right: boolean
  text: WysiwygType
  items: Array<
    {
      type: 'services_link',
      link: {preview: PreviewType}
    } |
    {
      type: 'services_item',
      link: LinkType,
      image: ImageType,
      text: WysiwygType
    }
  >
}

export function previewProcessServices(block: ServicesPreviewType): ServicesType {
  return {
    ...block,
    items: block.items.map(item => {
      if(item.type === 'services_item') return item

      const preview = item?.link?.preview
      if(!preview) return null

      return {
        link: {url: preview.url},
        image: preview.image,
        text: preview.description
      }
    })
  }
}
