import {LinkType} from 'types/basictypes'
import {InpagenavType} from 'blocks/inpagenav/inpagenav'

type InpagenavPreviewType = {
  type: 'inpagenav',
  display_dark: false,
  links: Array<LinkType>
}

export function previewProcessInpagenav(block: InpagenavPreviewType): InpagenavType {
  return {
    ...block,
    displayDark: block.display_dark,
    links: block.links.map(link => {
      return {
        id: link.url.replace('internal:', ''),
        label: link.title
      }
    })
  }
}
