import './press.scss'

import {Block, BlockType} from 'blocks/block'
import {Blocks} from 'blocks/blocks'
import {Hero} from 'blocks/hero/hero'
import {Contentsidebar, ContentsidebarContent, ContentsidebarSidebar} from 'layout/contentsidebar'
import {Layout} from 'layout/layout'
import {Sidebar, SidebarContactpersonsType} from 'layout/sidebar/sidebar'
import {Theme} from 'layout/theme'
import React, {useState} from 'react'
import AnimateHeight from 'react-animate-height'
import {ImageType, WysiwygType} from 'types/basictypes'
import {t} from 'util/i18'
import {Mod, styler} from 'util/style'
import {PreviewType} from 'layout/preview/previewtype'

const styles = styler('press')

export type PressType = {
	mod?: Mod<'preview'>
	blocks: Array<BlockType>
	content: Array<BlockType>
	background_image?: ImageType
	title: string
	description?: WysiwygType
	more?: WysiwygType
	contactpersons: Array<SidebarContactpersonsType>
	preview: PreviewType
}

export const Press: React.FC<PressType> = data => {
	const {
		blocks,
		content,
		background_image,
		title,
		description,
		more,
		contactpersons,
		mod,
		preview: {
			date,
			tags
		}
	} = data
	const [isVisible, setVisible] = useState(false)

	return (
		<Layout>
			<div className={styles().mod(mod)}>
				<Hero
					background_image={background_image}
					subtitle={'Persbericht'}
					title={title}
					description={description}
				/>
				<Contentsidebar className={styles('contentsidebar')}>
					<ContentsidebarContent>
						<div
							className={styles('contentsidebar-content').is({
								visible: isVisible
							})}
						>
							<AnimateHeight
								height={isVisible ? 'auto' : 450}
								duration={500}
								animateOpacity={true}
							>
								<Blocks fullwidth={true}>
									{content.map((block, i) => (
										<Block {...block} blocks={blocks} key={i} />
									))}
								</Blocks>
							</AnimateHeight>
						</div>
						{!isVisible && (
							<div className={styles('contentsidebar-cta')}>
								<Theme.Button onClick={() => setVisible(true)} mod="rounded">
									<Theme.ButtonText>{t('press.readmore')}</Theme.ButtonText>
									<Theme.ButtonIcon />
								</Theme.Button>
							</div>
						)}
					</ContentsidebarContent>
					<ContentsidebarSidebar>
						<Sidebar
							contactpersons={contactpersons}
							tags={tags}
							date={date}
							more={more}
						/>
					</ContentsidebarSidebar>
				</Contentsidebar>
				<Blocks>
					{blocks.map((block, i) => (
						<Block {...block} blocks={blocks} key={i} />
					))}
				</Blocks>
			</div>
		</Layout>
	)
}
