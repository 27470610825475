import './sidebar.scss'

import {Icon} from 'assets/icon'
import {Theme} from 'layout/theme'
import React, {useState} from 'react'
import {ImageType, WysiwygType} from 'types/basictypes'
import {t} from 'util/i18'
import {styler} from 'util/style'
import AnimateHeight from 'react-animate-height'
import {Link} from 'util/link'
import {GatsbyImageProps} from 'gatsby-image'
import {Image} from 'layout/partials/image'
import {useReadingroomSearchParams} from 'blocks/readingroom/readingroom'

const styles = styler('sidebar', [
	'authors',
	'contactpersons',
	'tags',
	'date',
	'more'
])

export type SidebarType = {
	authors?: Array<SidebarAuthorType>
	contactpersons?: Array<SidebarContactpersonsType>
	tags?: Array<string>
	date?: string
	more?: WysiwygType
}

export type SidebarAuthorType = {
	image?: ImageType
	name: string
	function?: string
	description?: WysiwygType
}

export type SidebarContactpersonsType = {
	name: string
	function?: string
	mobile?: string
	phone?: string
	email?: string
}

export type SidebarTagType = {
	name?: string
}

export const Sidebar: React.FC<SidebarType> = data => {
	const {authors, contactpersons, tags, date, more} = data

	return (
		<div className={styles()}>
			<SidebarAuthors authors={authors} />
			<SidebarContactpersons contactpersons={contactpersons} />
			<SidebarMore more={more} />
			<SidebarTags tags={tags} />
			<SidebarDate date={date} />
		</div>
	)
}

export const SidebarAuthors: React.FC<{
	authors?: Array<SidebarAuthorType>
}> = data => {
	const {authors} = data
	if (!authors || authors.length === 0) return null

	return (
		<div className={styles.authors()}>
			{authors.map((author, i) => (
				<Author author={author} key={i} />
			))}
		</div>
	)
}

export const Author: React.FC<{
	author?: SidebarAuthorType
}> = data => {
	const {author} = data
	const [isOpen, setState] = useState(true)
	if (!author) return null

	return (
		<div className={styles.authors('item')}>
			{author.image && <Image image={author.image} aspectRatio='free' />}
			<div className={styles.authors('item-top')}>
				<div className={styles.authors('item-top-info')}>
					<div className={styles.authors('item-name')}>{author.name}</div>
					{author.function && (
						<div className={styles.authors('item-function')}>
							{author.function}
						</div>
					)}
				</div>
				<a
					className={styles.authors('toggle').is({open: isOpen})}
					onClick={() => setState(!isOpen)}
				>
					<span className={styles.authors('item-toggle-icon')}>
						<Icon icon="ChevronDown" />
					</span>
				</a>
			</div>

			{author.description && (
				<div className={styles.authors('item-description')}>
					<AnimateHeight height={isOpen ? 'auto' : 0} animateOpacity={true}>
						<Theme.Wysiwyg>{author.description}</Theme.Wysiwyg>
					</AnimateHeight>
				</div>
			)}
		</div>
	)
}

export const SidebarContactpersons: React.FC<{
	contactpersons?: Array<SidebarContactpersonsType>
}> = data => {
	const {contactpersons} = data
	if (!contactpersons || contactpersons.length === 0) return null

	return (
		<div className={styles.contactpersons()}>
			{contactpersons.map((person, i) => (
				<div key={i} className={styles.contactpersons('item')}>
					<div className={styles.contactpersons('item-name')}>
						{person.name}
					</div>
					{person.function && (
						<div className={styles.contactpersons('item-function')}>
							{person.function}
						</div>
					)}
					<div className={styles.contactpersons('item-contact')}>
						{person.mobile && (
							<div
								className={styles
									.contactpersons('item-contact-icon')
									.mod('mobile')}
							>
								<a href={`tel:${person.mobile}`}>
									<Icon icon="Mobile" />
								</a>
							</div>
						)}
						{person.phone && (
							<div
								className={styles
									.contactpersons('item-contact-icon')
									.mod('phone')}
							>
								<a href={`tel:${person.phone}`}>
									<Icon icon="Phone" />
								</a>
							</div>
						)}
						{person.email && (
							<div
								className={styles
									.contactpersons('item-contact-icon')
									.mod('email')}
							>
								<a href={`mailto:${person.email}`}>
									<Icon icon="Envelope" />
								</a>
							</div>
						)}
					</div>
				</div>
			))}
		</div>
	)
}

export const SidebarMore: React.FC<{
	more?: WysiwygType
}> = data => {
	const {more} = data
	if (!more) return null

	return (
		<div className={styles.more()}>
			<Theme.Wysiwyg>{more}</Theme.Wysiwyg>
		</div>
	)
}

export const SidebarTags: React.FC<{
	tags?: Array<string>
}> = data => {
	const {createUrl} = useReadingroomSearchParams()
	const {tags} = data
	if (!tags || tags.length === 0) return null

	return (
		<div className={styles.tags()}>
			<p className={styles.tags('label')}>{t('sidebar.tags')}:</p>
			<div className={styles.tags('items')}>
				{tags.map((tag, i) => (
					<Link to={createUrl({tags: [tag]})} key={i}>
						<div className={styles.tags('items-item')}>
							<div className={styles.tags('items-item-tag')}>{tag}</div>
						</div>
					</Link>
				))}
			</div>
		</div>
	)
}

export const SidebarDate: React.FC<{date?: string}> = data => {
	const {date} = data
	if (!date) return null

	return (
		<div className={styles.date()}>
			<p className={styles.date('label')}>{t('sidebar.date')}:</p>
			<p>{date}</p>
		</div>
	)
}
