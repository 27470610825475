import './preview.scss'

import {Article} from 'layout/pages/article/article'
import {Event} from 'layout/pages/event'
import {Page} from 'layout/pages/page/page'
import {Press} from 'layout/pages/press'
import * as langs from 'locale'
import {Theme} from 'layout/theme'
import React, {useContext, useEffect} from 'react'
import {styler} from 'util/style'
import {DEV_PREVIEW_URL, PREVIEW_URL} from 'util/urls'
import {Researchproject} from 'layout/pages/researchproject'
import {BlockType} from 'blocks/block'
import {Case} from 'layout/pages/case'
import {previewProcessHero} from 'blocks/hero/hero_preview'
import {previewProcessInpagenav} from 'blocks/inpagenav/inpagenav_preview'
import {previewProcessOverview} from 'blocks/overview/overview_preview'
import {previewProcessImageblock} from 'blocks/imageblock/imageblock_preview'
import {previewProcessServices} from 'blocks/services/services_preview'
import {useUrlParams} from 'util/uselocation'
import {PreviewContext} from 'util/previewcontext'
import {BlocksContext} from 'blocks/blockscontext'
import {I18n} from 'util/i18'
import {EditCMS} from 'layout/editcms'
import {Job} from 'layout/pages/job'
import {useFetcher} from 'util/fetcher'
import {Errors} from 'layout/pages/preview/errors'
import {ProductContext} from 'layout/pages/product/product'

const styles = styler('preview', ['banner'])

function sendHeightToParent() {
	window.parent.postMessage({ height: document.body.scrollHeight }, '*')
}

export const Preview: React.FC<{
	nodeID?: number
}> = ({nodeID}) => {
	const { params: {token}} = useUrlParams()

	let url = null
	if(nodeID) url = DEV_PREVIEW_URL(nodeID)
	if(token) url = PREVIEW_URL(token as string)
	const {data} = useFetcher(url)

	useEffect(() => {
		window.addEventListener('resize', () => {
			sendHeightToParent()
		})
		sendHeightToParent()
	})

	if (!data) return <div>Loading preview..</div>

	return (
		<I18n language={data.lang} data={langs[data.lang]}>
			<PreviewContext.Provider value={true}>
				<BlocksContext.Provider value={{
					nodeID: data.nodeID,
					blocks: preProcessBlocks(data.blocks || []),
					pageTitle: data.title,
					pageType: data.type,
					parents: [],
					relatedContent: [],
					relatedJobs: [],
					url: data.url
				}}>
					<EditCMS type="node" id={data.nodeID} />
					<div className="mod-preview">
						<PreviewBanner url={data.url} isPublished={data.is_published} />
						<PreviewContent data={data} />
						<Errors />
					</div>
				</BlocksContext.Provider>
			</PreviewContext.Provider>
		</I18n>
	)
}

const PreviewBanner: React.FC<{
	url: string
	isPublished: boolean
}> = ({ isPublished, url }) => {

	return (
		<div className={styles.banner()}>
			<div className={styles.banner('message')}>
				{isPublished ?
					`This is a live preview. It takes 5-10 minutes for the live page to refresh after making a change.` :
					`This is a staging preview of an unpublished version. It takes 5-10 minutes for the live page to appear online after publishing it.`
				}
			</div>
			{isPublished && <Theme.Button to={url} target="_blank">
				<Theme.ButtonText>View live</Theme.ButtonText>
			</Theme.Button>}
		</div>
	)
}

const PreviewContent: React.FC<{ data: any }> = ({ data }) => {
	const ctx = useContext(BlocksContext)

	switch (data.pagetype) {
		case 'page':
			const parents = []
			let parent = data.parent
			while(parent) {
				parents.push(parent)
				parent = parent.parent
			}
			return (
				<BlocksContext.Provider value={{...ctx, parents}}>
					<Page
						{...data}
						blocks={preProcessBlocks(data.blocks)}
						mod="preview"
					/>
				</BlocksContext.Provider>
			)

		case 'product':
			const productParents = []
			let productParent = data.parent
			while(productParent) {
				productParents.push(productParent)
				productParent = productParent.parent
			}

			return (
				<BlocksContext.Provider value={{...ctx, parents: productParents}}>
					<ProductContext.Provider value={{
						description: data.description,
						image: data.product_image,
						filters: (data.product_type[0]?.product_filters || []).map(filter => ({filter, label: filter}))
					}}>
						<Page
							{...data}
							blocks={preProcessBlocks(data.blocks)}
							mod="preview"
						/>
					</ProductContext.Provider>
				</BlocksContext.Provider>
			)

		case 'event':
			return (
				<Event
					{...data}
					blocks={preProcessBlocks(data.blocks)}
					mod="preview"
				/>
			)
		case 'case':
			return (
				<Case
					{...data}
					blocks={preProcessBlocks(data.blocks)}
					mod="preview"
					background_overlay={data.background_image && data.background_image.overlay_percentage}
					tags={data.tags.map(tag => tag.name)}
				/>
			)
		case 'research_project':
			return (
				<Researchproject
					{...data}
					marketicon={data.market.iconmarket_logo}
					blocks={preProcessBlocks(data.blocks)}
					mod="preview"
				/>
			)
		case 'press':
			return (
				<Press
					{...data}
					blocks={preProcessBlocks(data.blocks)}
					backgroundimage={data.background_image}
					contactpersons={data.contactpersons.map(auth => ({
						...auth,
						function: auth.contact_function,
						mobile: auth.contact_mobile_number,
						phone: auth.contact_phone_number,
						email: auth.contact_email
					}))}
					tags={data.tags.map(tag => tag.name)}
					date={data.date}
					label={data.label ? data.label.name : null}
					related={[]}
					mod="preview"
				/>
			)
		case 'job':
			return (
				<Job
					{...data}
					blocks={preProcessBlocks(data.blocks)}
				/>
			)

		case 'article':
			return (
				<Article
					{...data}
					blocks={preProcessBlocks(data.blocks)}
					backgroundimage={data.background_image}
					label={data.label ? data.label.name : ''}
					authors={data.authors.map(auth => {
						if (auth.type === 'article_existing_author') {
							return {
								...auth,
								image: auth.existing_author_author.author_avatar_image,
								name: auth.existing_author_author.name,
								function: auth.existing_author_author.author_function,
								description: auth.existing_author_author.avatar_bio
							}
						}

						if (auth.type === 'article_author') {
							return {
								...auth,
								description: auth.bio
							}
						}
					})}
					tags={data.tags.map(tag => tag.name)}
					date={data.date}
					related={[]}
					mod="preview"
				/>
			)
	}

	return <h1>Unknown page type: {data.pagetype}</h1>
}

function preProcessBlocks(blocks): Array<BlockType> {
	if(!blocks) return []
	return blocks.flatMap(bl => {
		const processed = preProcessBlock(bl)
		if(Array.isArray(processed)) return processed
		return [processed]
	})
}

function preProcessBlock(bl: any): BlockType | Array<BlockType> {
	if(!bl) return []

	switch (bl.type) {
		case 'inpagenav':
			return { type: 'inpagenav', ...previewProcessInpagenav(bl) }
		case 'hero':
			return { type: 'hero', ...previewProcessHero(bl) }
		case 'overview':
			return { type: 'overview', ...previewProcessOverview(bl) }
		case 'imageblock':
			return { type: 'imageblock', ...previewProcessImageblock(bl) }
		case 'services':
			return { type: 'services', ...previewProcessServices(bl) }
		case 'prototype':
			if(!bl.prototype) return []
			return preProcessBlocks(bl.prototype.blocks)

		case 'related':
			return {
				...bl,
				fixed: []
			}
		case 'cases':
			return {
				...bl,
				items: bl.items.map(item => ({
					...item,
					image: item.cases_background_image
				}))
			}
		case 'specifications':
			return {
				...bl,
				items: bl.specification_items.map(item => ({
					...item
				}))
			}
		case 'testimonials':
			return {
				...bl,
				items: bl.items.map(item => ({
					...item,
					avatar_image: item.testimonials_avatar_image,
					bg_image: item.testimonials_bg_image,
					link: item.testimonials_link,
					name: item.testimonials_name,
					quote: item.testimonials_quote,
					type: item.type.replace('testimonials_', '')
				}))
			}
		case 'gallery':
			return {
				...bl,
				items: bl.items.map(item => ({
					...item,
					thumbnail: item.thumbnail || item.image,
					type: item.type.replace('gallery_', '')
				}))
			}
		case 'reading_room':
			return {
				...bl,
				highlighted: (bl.reading_featured_articles || []).map(item => ({
					title: item.title,
					description: item.preview.description,
					url: item.url,
					image: item.background_image,
					tags: (item.terms || []).map(tag => tag.name)
				})),
				title: bl.reading_title,
				categories: bl.reading_categories.map(category => category.name)
			}
		default:
			return { ...bl }
	}
}
