import {HeroType} from 'blocks/hero/hero'
import {LinkType, PreviewImageType, WysiwygType} from 'types/basictypes'

export type HeroPreviewType = {
	type: 'hero'
	back: LinkType
	background_image: PreviewImageType
	buttons: Array<LinkType>
	description: WysiwygType
	title: string
}

export function previewProcessHero(block: HeroPreviewType): HeroType {
	return {
		...block,
		description: block.description,
		background_image: block.background_image
	}
}
