import 'layout/pages/article/article.scss'

import logoImg from 'assets/images/imec-logo-white.svg'
import {Articles} from 'blocks/articles/articles'
import {Block, BlockType} from 'blocks/block'
import {Blocks} from 'blocks/blocks'
import {Link} from 'gatsby'
import {Contentsidebar, ContentsidebarContent, ContentsidebarSidebar} from 'layout/contentsidebar'
import {GridProvider} from 'layout/grid'
import {BgFixed} from 'layout/partials/bg'
import {Sidebar, SidebarAuthorType} from 'layout/sidebar/sidebar'
import {Theme} from 'layout/theme'
import React from 'react'
import {ImageType, WysiwygType} from 'types/basictypes'
import {t, useTranslation} from 'util/i18'
import {Mod, styler} from 'util/style'
import {Mousescroll} from 'layout/mousescroll'
import {GatsbyImageProps} from 'gatsby-image'
import {PreviewType} from 'layout/preview/previewtype'
import {Share} from 'blocks/share/share'
import {Icon} from 'assets/icon'
import {useLinks} from 'util/links'

const styles = styler('article', ['hero', 'herotop'])

export type ArticleType = {
	mod?: Mod<'preview'>
	background_image?: ImageType
	title: string
	description?: WysiwygType
	summary?: WysiwygType
	more?: WysiwygType
	authors: Array<SidebarAuthorType>
	blocks: Array<BlockType>
	preview: PreviewType
}

export type ArticleHeroType = {
	background_image?: ImageType
	subtitle?: string
	title: string
	description?: WysiwygType
	summary?: WysiwygType
}

export const Article: React.FC<ArticleType> = data => {
	const {
		background_image,
		title,
		description,
		summary,
		more,
		blocks,
		authors,
		mod,
		preview: {
			tags,
			date,
			label
		}
	} = data
	const {article: {readmore}} = useTranslation()

	return (
		<GridProvider s={{gap: [5, 50]}} gap={[30, 50]}>
			<div className={styles().mod(mod)}>
				<ArticleHero
					background_image={background_image}
					subtitle={label}
					title={title}
					description={description}
					summary={summary}
				/>
				<span id="scroll"></span>
				<Contentsidebar className={styles('contentsidebar')}>
					<ContentsidebarContent>
						<Blocks fullwidth={true}>
							{blocks.map((bl, i) => (
								<Block {...bl} blocks={blocks} key={i} />
							))}
						</Blocks>
					</ContentsidebarContent>
					<ContentsidebarSidebar>
						<Sidebar authors={authors} tags={tags} date={date} more={more} />
					</ContentsidebarSidebar>
				</Contentsidebar>
				<Theme.OuterContainer>
					<div className={styles('bottom')}>
						<Share />
						<Articles title={readmore} />
					</div>
				</Theme.OuterContainer>
			</div>
		</GridProvider>
	)
}

export const ArticleHero: React.FC<ArticleHeroType> = data => {
	const {
		background_image,
		subtitle,
		title,
		description,
		summary
	} = data

	return (
		<div
			className={styles.hero().mod({
				image: background_image
			})}
		>
			{background_image && (
				<BgFixed image={background_image} />
			)}
			<ArticleHeroTop />
			<Theme.OuterContainer>
				<Theme.Container>
					<div className={styles.hero('row')}>
						<div className={styles.hero('content')}>
							{subtitle && (
								<p className={styles.hero('content-subtitle')}>{subtitle}</p>
							)}
							<h1 className={styles.hero('content-title')}>{title}</h1>
							{description && (
								<div className={styles.hero('content-description')}>
									<Theme.Wysiwyg>{description}</Theme.Wysiwyg>
								</div>
							)}
						</div>
						{summary && (
							<div className={styles.hero('summary')}>
								<span className={styles.hero('summary-title')}>
									In het kort
								</span>
								<Theme.TrustedText>{summary}</Theme.TrustedText>
							</div>
						)}
					</div>
				</Theme.Container>
			</Theme.OuterContainer>
			<div className={styles('scroll')}>
				<Theme.Container>
					<Mousescroll id="#scroll" />
				</Theme.Container>
			</div>
		</div>
	)
}

export const ArticleHeroTop: React.FC = () => {
	const {home, readingroom} = useLinks()

	return (
		<div className={styles.herotop()}>
			<div className={styles.herotop('left')}>
				<Link className={styles.herotop('left-cta')} to={readingroom.url}>
					<div className={styles.herotop('left-cta-circle')}>
						<Icon icon="ArrowLeft" />
					</div>
					<p className={styles.herotop('left-cta-text')}>{t('article.back')}</p>
				</Link>
			</div>
			<div className={styles.herotop('center')}>
				<Link className={styles.herotop('center-logo')} to={home.url}>
					<img
						className={styles.herotop('center-logo-img')}
						src={logoImg}
						alt="imec logo"
					/>
				</Link>
			</div>
			<div className={styles.herotop('right')} />
		</div>
	)
}
