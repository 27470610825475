import {Block, BlockType} from 'blocks/block'
import {Blocks} from 'blocks/blocks'
import {Hero} from 'blocks/hero/hero'
import {Layout} from 'layout/layout'
import React from 'react'
import {ImageType, WysiwygType} from 'types/basictypes'
import {Mod, styler} from 'util/style'

const styles = styler('press')

export type CaseType = {
	mod?: Mod<'preview'>
	blocks: Array<BlockType>
	background_image?: ImageType
	title: string
	description?: WysiwygType
}

export const Case: React.FC<CaseType> = data => {
	const {
		blocks,
		background_image,
		title,
		description,
		mod,
	} = data

	return (
		<Layout>
			<div className={styles().mod(mod)}>
				<Hero
					background_image={background_image}
					title={title}
					description={description}
				/>
				<Blocks>
					{blocks.map((block, i) => (
						<Block
							{...block}
							blocks={blocks}
							key={i} />
					))}
				</Blocks>
			</div>
		</Layout>
	)
}
