import {Block, BlockType} from 'blocks/block'
import {Layout} from 'layout/layout'
import React from 'react'
import {Blocks} from 'blocks/blocks'

export type EventType = {
	blocks: Array<BlockType>
	eventInfo?: string
}

export const Event: React.FC<EventType> = data => {
	const {blocks, eventInfo} = data

	const cases = blocks.length && blocks[0].type === 'cases' ? blocks[0] : null
	const filteredBlocks = cases ? blocks.slice(1) : blocks

	return (
		<Layout cases={cases}>
			<Blocks>
				{filteredBlocks.map((block, i) => (
					<Block
						{...block}
						blocks={filteredBlocks}
						key={i}
						eventInfo={eventInfo}
					/>
				))}
			</Blocks>
		</Layout>
	)
}
